import {
    FETCH_RECIPIENT_CONNECTOR_CONFIGS,
    RECIPIENT_CONNECTOR_AUTH_ERROR,
    DELETE_RECIPIENT_CONNECTOR_CONFIGS,
    DELETE_RECIPIENT_CONNECTOR_CONFIG,
    DELETE_ALL_RECIPIENT_CONNECTOR_CONFIGS,
    LOADER_RECIPIENT_CONNECTOR_INITIALISATION,
    RECIPIENT_CONNECTOR_INITIALISATION,
    LOADER_RECIPIENT_CONNECTOR_LIST,
    FETCH_RECIPIENT_CONNECTOR_TYPES,
    LOADER_RECIPIENT_CONNECTOR_TYPES_FETCH,
} from "../actions/types";

const initialState = {
    recipientConnectorConfigs: {},
    recipientConnectorInitialised: {},
    deletedRecipientConnectorConfigs: [],
    deletedRecipientConnectorConfig: {},
    recipientConnectorTypes: {},
    deletedAllRecipientConnectorConfigs: null,
    error: null,
    contents: {},
    loaderRCList: false,
    loaderRCTypes: false,
    loaderRCInit: false,
    loaderRCCList: false,
    loaderRCCFetch: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_RECIPIENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                recipientConnectorConfigs: action.payload,
                loaderRCList: false,
            };
        case RECIPIENT_CONNECTOR_AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case RECIPIENT_CONNECTOR_INITIALISATION:
            return {
                ...state,
                recipientConnectorInitialised: action.payload,
                loaderRCInit: false,
            };
        case FETCH_RECIPIENT_CONNECTOR_TYPES:
            return {
                ...state,
                recipientConnectorTypes: action.payload,
                loaderRCTypes: false,
            };
        case DELETE_RECIPIENT_CONNECTOR_CONFIG:
            return {
                ...state,
                deletedRecipientConnectorConfig: action.payload,
                loaderRCList: false,
            };
        case DELETE_RECIPIENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                deletedRecipientConnectorConfigs: action.payload,
                loaderRCList: false,
            };

        case DELETE_ALL_RECIPIENT_CONNECTOR_CONFIGS:
            return {
                ...state,
                deletedAllRecipientConnectorConfigs: action.payload,
                loaderRCList: false,
            };
        case LOADER_RECIPIENT_CONNECTOR_LIST:
            return {
                ...state,
                loaderRCList: true,
            };
        case LOADER_RECIPIENT_CONNECTOR_INITIALISATION:
            return {
                ...state,
                loaderRCInit: true,
            };
        case LOADER_RECIPIENT_CONNECTOR_TYPES_FETCH:
            return {
                ...state,
                loaderRCTypes: true,
            };
        default:
            return state;
    }
}
