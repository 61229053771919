export default {
    //actions
    edit: "bearbeiten",
    create: "erstellen",
    delete: "löschen",
    deleteSelected: "Ausgewählte Elemente löschen",
    map: "Zuweisen",
    confirm: "Bestätigen",
    close: "Schließen",

    //SENDOUTS
    sendouts: "Kampagnen",
    sendout: "Kampagne",

    "sendout.save": "Kampagne speichern",
    "sendouts.create": "Eine neue Kampagne erstellen",
    "sendouts.edit": "Kampagne bearbeiten",
    "sendouts.delete": "Kampagne löschen",
    "sendouts.deleteAll": "Alle Kampagnen löschen",
    "sendouts.edited": "Kampagne wurde erfolgreich bearbeitet",
    "sendouts.deleted": "Kampagne wurde erfolgreich gelöscht",
    "sendouts.created": "Kampagne wurde erfolgreich erstellt",
    "sendouts.triggerJob": "Layout Job triggern",
    "sendouts.wizard": "Kampagne in Wizard bearbeiten",

    "sendout.form": "Kampagnenformular",
    "sendout.view": "Kampagne anschauen",
    "sendout.check": "Kampagne überprüfen",
    "sendout.confirmed": "Kampagne wurde bestätigt",

    //Chapter
    chapter: "Kapitel",
    chapters: "Kapitel",
    "chapter.create": "Kapitel erstellen",
    "chapter.manage": "Kapitel verwalten",
    "chapter.edit": "Kapitel bearbeiten",
    "chapter.import": "Kapitel importieren",
    "chapter.delete": "Kapitel löschen",
    "chapter.deleteAll": "Alle Kapitel löschen",
    "chapter.mapContents": "Inhalte Kapitel zuweisen",
    "chapter.show": "Abschnitte anzeigen",
    "chapter.noshow": "Abschnitte minimieren",

    //Layoutjob
    layoutJob: "Layout Auftrag",
    layoutJobs: "Layout Aufträge",
    "layoutJob.create": "Layout Auftrag erstellen",
    "layoutJobs.edit": "Layout Auftrag bearbeiten",
    "layoutJobs.delete": "Layout Auftrag löschen",

    //RecipientList
    recipientList: "Empfängerliste",
    "recipientList.edit": "Empfängerliste bearbeiten",
    "recipients.map": "Empfänger zuweisen",

    //contents
    "contents.map": "Inhalte zuweisen",

    //ContentSelector
    contentSelector: "Empfehlungen",
    contentSelectorType: "Empfehlungsrichtlinie",

    //ContentConnector
    contentConnector: "Datenquelle",
    contentConnectors: "Datenquellen",
    contentConnectorType: "Datenquelle",
    "contentConnector.delete": "Datenquelle löschen",
    "contentConnector.create": "Neue Datenquelle erstellen",
    "contentConnector.deleteAll": "Alle Datenquellen löschen",
    "contentConnectors.view": "Inhaltstypen anzeigen",

    //Content Builder Model
    contentBuilderModel: "Content Builder Modell",
    "contentBuilderModel.create": "Content Builder Modell erstellen",
    "contentBuilderModel.edit": "Content Builder Modell bearbeiten",
    "contentBuilderModel.delete": "Content Builder Modell löschen",
    "contentBuilderModel.deleteAll": "Alle Content Builder Modelle löschen",

    //Section
    section: "Abschnitt",
    sections: "Abschnitte",
    "section.showContents": "Inhalte anzeigen",
    "section.create": "Abschnitt erstellen",
    "section.edit": "Abschnitt bearbeiten",
    "section.delete": "Abschnitt löschen",

    //Job
    job: "Auftrag",
    jobs: "Aufträge",

    //Css Editor
    page: "Seite",
    pageNumber: "Seitenzahl",

    //Textblocks
    textBlocks: "Textblöcke",
    "textBlocks.delete": "Textblock löschen",
    "textBlocks.deleteAll": "Alle Textblöcke löschen",
    "textBlocks.edit": "Textblock bearbeiten",
    "textBlocks.create": "Textblock erstellen",

    textBlock: "Textblock",
    "textBlock.delete": "Textblock löschen",
    "textBlock.edit": "Textblock bearbeiten",
    "textBlock.create": "Textblock erstellen",
    property: "Eigenschaft",

    //Index Table
    indexTable: "Verzeichnisse",
    "indexTable.indexType": "Art des Verzeichnisses",
    "indexTable.property": "Attribut",
    "indexTable.headline": "Überschrift",
    "indexTable.indexTypePlaceHolder": "Typ des Verzeichnisses auswählen",
    "indexTable.propertyPlaceHolder": "Attribut auswählen",
    "indexTable.headlinePlaceHolder": "Überschrift eingeben",
    "indexTable.create": "Verzeichnis erstellen",
    "indexTable.edit": "Verzeichnis bearbeiten",
    "indexTable.delete": "Verzeichnis löschen",
    "indexTable.deleteAll": "Alle Verzeichnisse löschen",

    //CSS
    "css.management": "Css-Verwaltung",
    cssSelectorName: "CSS Selector Name",
    "css.created": "Css-Datei wurde erfolgreich erstellt",
    "css.edited": "Css-Datei wurde erfolgreich bearbeitet",
    "css.delete": "Css Datei löschen",
    "css.create": "Css Datei erstellen",
    "css.edit": "Css Datei bearbeiten",
    "error.cssSelectorName": "Ungültiger CSS Selector Name",
    cssFile: "CSS-Datei",
    "placeHolder.css": "Geben Sie hier CSS ein....",

    //Output
    outputManagement: "Ausgabemanagement",
    outputConfig: "Ausgabekonfiguration",
    outputConfigType: "Art der Ausgabekonfiguration",
    "OutputManagement.OutputTypePlaceHolder": "Ausgangstyp wählen",

    //Layout Generator
    layoutGenerator: "Layout Generator",
    layoutGeneratorConfig: "Layout Generator Konfiguration",

    //Recipient
    recipient: "Empfänger",
    "recipient.placeholder": "Empfängerplatzhalter",

    //Date
    "date.placeholder": "Datumsplatzhalter",
    "date.pattern": "Datumsformat",
    "date.time": "Zeitpunkt",
    "date.ger.short": "Deutsch, kurz (dd.MM.yyyy)",
    "date.ger.long": "Deutsch, lang (EEEE, DD.MM.YYYY)",
    "date.us.short": "Vereinigten Staaten, kurz (M/d/yyyy)",
    "date.us.long": "Vereinigten Staaten, lang (MMMM dd, yyyy)",
    "date.en.short": "Englisch, kurz (dd/MM/yyyy)",
    "date.en.long": "Englisch, lang (dd MMMM yyyy)",
    "date.manual.input": "Datumsformat manuell eingeben",
    "date.manual": "Datum manuell angeben",
    "date.now": "'Jetzt'-Parameter",

    //Messages

    saveConfirm:
        "Bitte speichern Sie die aktuellen Änderungen, bevor Sie auf einen anderen Tab wechseln.",
    saveBeforSwitch:
        "Bitte speichern Sie die aktuellen Änderungen, bevor Sie zu dem Editor wechseln.",
    itemsPerPageSwitch:
        "Wenn Sie die Elemente pro Seite ändern, werden alle ausgewählten Elemente nicht mehr ausgewählt.",

    //input
    left: "Links",
    right: "Rechts",
    top: "Oben",
    bottom: "Unten",
    imageUrl: "Bild-Url",
    backgroundColor: "Hintergrundfarbe",
    font: "Schrift",
    fontFamily: "Schriftart",
    fontSize: "Schriftgröße",
    lineHeight: "Schrifthöhe",
    fontColor: "Schriftfarbe",
    suffix: "Suffix",
    width: "Breite",
    height: "Höhe",
    columnCount: "Spaltenanzahl",
    paddingLeft: "Innenabstand links",
    paddingRight: "Innenabstand rechts",
    paddingTop: "Innenabstand oben",
    paddingBottom: "Innenabstand unten",
    margin: "Außenabstand",
    withPageNumber: "Mit Seitenzahl",
    background: "Hintergrund",
    align: "Ausrichtung",
    spaces: "Abstände",

    //Layout Job
    dashboard: "Übersichtsseite",
    content: "Inhalt",
    contentTypes: "Inhaltstypen",
    workspaces: "Kunden",
    workspace: "Kunde",
    users: "Benutzer",
    "confirm.message": "Sind sie sicher, dass sie den Eintrag löschen wollen?",
    "confirm.yes": "Ja",
    "confirm.no": "Nein",
    workspaceName: "Kundenname",
    register: "Registrieren",
    password: "Passwort",
    "password.change": "Passwort ändern",
    "password.old": "Altes Passwort",
    "password.new": "Neues Passwort",
    "password.confirm": "Passwort bestätigen",
    viewLayoutJob: "Layout Job ansehen",
    childLayoutJob: "Unteraufträge",
    back: "Zurück",
    error: "Fehler",
    parentLayoutJobId: "Oberauftrag",
    detailView: "Detailansicht",
    "upload.file": "Datei hochladen",
    file: "Datei",
    welcome: "Willkommen",
    account: "Konto",
    title: "Titel",
    firstName: "Vorname",
    surname: "Nachname",
    foreignId: "Fremdsystem Id",
    street: "Straße",
    zipCode: "PLZ",
    city: "Stadt",
    country: "Land",
    mail: "E-Mail",
    alsJobId: "ALS Auftrags-ID",
    alsJobStatus: "ALS Auftragsstatus",
    salutation: "Anrede",
    voucherCode: "Gutscheincode",
    voucherExpirationDate: "Gültigkeitsdatum Gutscheincode",
    home: "Home",
    "header.account": "Konto",
    "header.logout": "Abmelden",
    "login.welcomeText": "deutscher Willkommenstext...",
    "login.goToLogin": "zum Login",
    "content.list": "Inhaltsverzeichnis",
    slNum: "#",
    icon: "Icon",
    label: "Bezeichnung",
    name: "Name",
    modifiedAt: "Geändert am",
    modifiedBy: "Geändert von",
    createdAt: "Erstellt am",
    createdBy: "Erstellt von",
    search: "Suche",
    eMail: "E-Mail",
    "contentType.list": "Inhaltstypen Liste",
    foreignSystemId: "Fremdsystem Id",
    productId: "Artikelnummer",
    subtitle: "Untertitel",
    description: "Beschreibung",
    currency: "Währung",
    link: "Link URL",
    priceGross: "Bruttopreis",
    externalImage: "Externe Bild URL",
    specialOfferGross: "Brutto-Sonderpreis",
    "recipient.import": "Empfänger auswählen",
    contentType: "Inhaltstyp",
    "content.create": "Inhalt erstellen",
    "content.edit": "Inhalt bearbeiten",
    "recipient.create": "Neuer Empfänger",
    "recipient.edit": "Empfänger bearbeiten",
    "content.import": "Inhalte aus einer Excel-Datei importieren",
    "content.delete": "Inhalt löschen",

    "job.delete": "Auftrag löschen",
    "recipient.delete": "Empfänger löschen",
    "input.imageUrl": "Bild URL",
    "contentType.new": "Neuer Inhaltstyp",
    "contentType.edit": "Inhaltstyp bearbeiten",
    "contentType.create": "Einen neuen Inhaltstyp erstellen",
    "contentType.delete": "Inhaltstyp löschen",
    contentTypeFieldName: "Inhaltstype Feldname",
    inputType: "Eingabe Typen",
    select: "Auswählen",
    isRequired: "ist erforderlich",
    useForAls: "für ALS verwendet",
    "workspace.list": "Kundenliste",
    "workspace.create": "Kunde erstellen",
    "workspace.edit": "Kunde bearbeiten",
    "workspace.delete": "Kunde löschen",
    "user.list": "Benutzerliste",
    "user.edit": "Benutzer bearbeiten",
    "user.fullName": "Vollständiger Name",
    "user.userName": "Benutzername",
    "user.delete": "Benutzer löschen",
    "deleteAll.job": "Alle Jobs löschen",
    "deleteAll.user": "Alle Benutzer löschen",
    "deleteAll.recipient": "Alle Empfänger löschen",
    "deleteAll.workspace": "Alle Kunden löschen",
    "deleteAll.contentGroups": "Alle Inhaltsgruppen löschen",
    "deleteAll.chapters": "Alle Kampagnenseiten löschen",
    "label.resultsPerPage": "Ergebnisse pro Seite ",
    "product.headline.newProduct": "Neuer Inhalt: Produkt",
    save: "Speichern",
    goToList: "Zur List",
    invalidName: "Ungültiger Name",
    invalidImageUrl: "Ungültige Bild URL",
    default: "Ungültige Eingabe",
    noData: "Keine Daten vorhanden",

    reset: "Zurücksetzen",
    "session.expiry": "Ihre Sitzung läuft ab in ",

    deleteAll: "Sind Sie sicher, dass Sie alle Artikel löschen möchten",
    "layout.generate": "Layouts generieren",
    "steps.completed": "Alle Schritte wurden erfolgreich ausgeführt",
    import: "Elemente aus einer Datei importieren",
    "user.create": "Benutzer erstellen",
    "contentGroup.create": "Inhaltsgruppe erstellen",
    "contentGroup.Edit": "Inhaltsgruppe bearbeiten",
    "contentGroup.delete": "Inhaltsgruppe löschen",
    contentGroup: "Inhaltsgruppe",
    css: "CSS",
    newSession: "Neue Sitzung",
    minPageCount: "Minimale Seitenzahl",
    maxPageCount: "Maximale Seitenzahl",
    "delete.all": "Sind sie sicher, dass sie alle Einträge löschen wollen?",
    "delete.selected":
        "Sind sie sicher, dass sie alle ausgewählten Einträge löschen wollen?",
    "delete.single": "Sind sie sicher, dass sie diesen Eintag löschen wollen?",

    itemsPerPage: "Ergebnisse pro Seite",
    saveContentGroup: "Inhaltsgruppe speichern",
    cancel: "Abbrechen",
    createNew: "Neu erstellen",
    drag: "Drag and Drop oder zum Durchsuchen anklicken",
    mediaType: "Medientyp",

    "selected.pre": "Du hast",
    "selected.post": "Einträge ausgewählt!",
    "chapterd.edit": "Kampagnenseite wurde erfolgreich bearbeitet",
    "chapterd.delete": "Kampagnenseite wurde erfolgreich gelöscht",
    "chapterd.create": "Kampagnenseite wurde erfolgreich erstellt",
    "chapterd.progress": "Fortschritt erfolgreich gespeichert",
    "workspace.edited": "Kunde wurde erfolgreich bearbeitet",
    "workspace.deleted": "Kunde wurde erfolgreich gelöscht",
    "workspace.created": "Kunde wurde erfolgreich erstellt",
    "content.edited": "Inhalt wurde erfolgreich bearbeitet",
    "content.deleted": "Inhalt wurde erfolgreich gelöscht",
    "content.created": "Inhalt wurde erfolgreich erstellt",
    "job.edited": "Job wurde erfolgreich bearbeitet",
    "job.deleted": "Job wurde erfolgreich gelöscht",
    "job.created": "Job wurde erfolgreich erstellt",
    "recipient.edited": "Empfänger wurde erfolgreich bearbeitet",
    "recipient.deleted": "Empfänger wurde erfolgreich gelöscht",
    "recipient.created": "Empfänger wurde erfolgreich erstellt",
    "user.editd": "Benutzer wurde erfolgreich bearbeitet",
    "user.deleted": "Benutzer wurde erfolgreich gelöscht",
    "user.created": "Benutzer wurde erfolgreich erstellt",
    "password.edit": "Passwort wurde erfolgreich geändert",
    "file.reject": "Abgelehnter Dateityp!",
    "file.unknown":
        "Dateityp wurde nicht erkannt! Es kann sein, dass diese Datei nicht mit dem System kompatibel ist.",
    "import.success": "Erfolgreicher Import",
    "session.expire": "Ihre Sitzung läuft ab in",
    "error.unknown": "Etwas ist schief gelaufen :(",
    "error.input.invalid": "Ungültige Eingabe",
    "error.form.fillForm": "Bitte füllen Sie die erforderlichen Felder aus",
    "sessionTimeOut.pre": "Ihre Sitzung läuft ab in ",
    "sessionTimeOut.post":
        " Bitte starten Sie eine neue Sitzung, um fortzufahren.",
    "sessionTimeOut.minutes": " Minuten & ",
    "sessionTimeOut.seconds": " Sekunden.",

    //Styling Templates
    "stylingTemplate.headline": "Vorlagen",
    "stylingTemplate.create": "Nue Vorlagen",
    "stylingTemplate.edit": "Vorlage bearbeiten",
    "stylingTemplate.select": "Vorlage auswählen",
    "stylingTemplate.shared": "AutLay Stile",
    "stylingTemplate.local": "Eigene Stil-Vorlagen",
    "stylingTemplate.fitLook": "Corporate Design anpassen",
    "fontWeights.fw100": "ultraleicht",
    "fontWeights.fw200": "extraleicht",
    "fontWeights.fw300": "leicht",
    "fontWeights.fw400": "normal",
    "fontWeights.fw500": "medium",
    "fontWeights.fw600": "halbfett",
    "fontWeights.fw700": "fett",
    "fontWeights.fw800": "extrafett",
    "fontWeights.fw900": "ultrafett",
    "labels.h1": "Überschrift",
    "labels.h2": "Unterüberschrift",
    "labels.letter": "Anschreiben",
    "labels.date": "Datum",
    "labels.default": "Standardformat",
    "labels.primary": "Primäre Formatierung",
    "labels.secondary": "Sekundäre Formatierung",
    "labels.product": "Produkt",
    "labels.product-price": "Produktpreis",
    "labels.logo": "Ihr Logo",
    "labels.header": "Kopfzeile",
    "labels.footer": "Fußzeile",
    "font.family": "Schriftart",
    "font.size": "Schriftgröße (in pt)",
    "font.weight": "Schriftstärke",
    "font.color": "Schriftfarbe",
    "previewImages.example": "Beispielhafte Darstellung",
    "previewImages.frontpage": "Vorderseite",
    "previewImages.backpage": "Rückseite",

    "mustHave.front": "Inhalte: Vorderseite",
    "mustHave.last": "Inhalte: Rückseite",
    "mustHave.fixPosition": "Feste Postition",

    "AssetManager.upload": "Wähle eine Datei oder füge eine neue hinzu",
    "AssetManager.latestUploadedFiles": "Zuletzt hochgeladene Dateien",
    "AssetManager.button": "Logo hinzufügen",
};
