/* eslint-disable */
export default {
    //CRUD
    edit: "edit",
    create: "create",
    delete: "delete",
    deleteSelected: "Delete selected items",
    map: "Map",
    confirm: "Confirm",
    close: "Close",

    //CAMPAINGS
    sendouts: "Campaigns",
    sendout: "Campaign",

    "sendout.save": "Save campaign",
    "sendouts.create": "Create a new campaign",
    "sendouts.edit": "Edit campaign",
    "sendouts.delete": "Delete campaign",
    "sendouts.deleteAll": "Delete all campaigns",
    "sendout.edited": "Campaign edited successfully",
    "sendout.deleted": "Campaign deleted successfully",
    "sendout.created": "Campaign created successfully",
    "sendouts.triggerJob": "Trigger layout job",
    "sendouts.wizard": "Edit campaign in wizard",

    "sendout.form": "Campaign form",
    "sendout.view": "View campaign",
    "sendout.check": "Check campaign",
    "sendout.confirmed": "Campaign has been confirmed",

    //Chapter
    chapter: "Chapter",
    chapters: "Chapters",
    "chapter.create": "Create a new chapter",
    "chapter.manage": "Manage Chapter",
    "chapter.edit": "Edit chapter",
    "chapter.delete": "Delete chapter",
    "chapter.deleteAll": "Delete all chapters",
    "chapter.import": "Import chapters",
    "chapter.show": "Show sections",
    "chapter.noshow": "Collapse sections",
    "chapter.mapContents": "Map contents to chapter",

    //Job

    job: "Job",
    jobs: "Jobs",
    //necessary for items per page selection
    childJobs: "Jobs",

    //Layoutjob
    layoutJob: "Layout Job",
    layoutJobs: "Layout Jobs",
    "layoutJobs.create": "Create Layout job",
    "layoutJobs.edit": "Edit layout job",
    "layoutJobs.delete": "Delete layout job",

    //Recipients
    "recipients.map": "Map recipients",
    "recipient.placeholder": "Recipient placeholder",

    //Date
    "date.placeholder": "Date placeholder",
    "date.pattern": "Date format",
    "date.time": "Date time",
    "date.ger.short": "German, short (dd.MM.yyyy)",
    "date.ger.long": "German, long (EEEE, DD.MM.YYYY)",
    "date.us.short": "United States, short (M/d/yyyy)",
    "date.us.long": "United States, long (MMMM dd, yyyy)",
    "date.en.short": "British, kurz (dd/MM/yyyy)",
    "date.en.long": "British, lang (dd MMMM yyyy)",
    "date.manual.input": "Enter date format manually",
    "date.manual": "Specify date manually",
    "date.now": "'Now' parameter",

    //contents
    "contents.map": "Map Contents",

    //ContentSelector
    contentSelector: "Recommendation",
    contentSelectorType: "Recommendation selector",

    //Section
    section: "Section",
    sections: "Sections",
    "section.create": "Create Section",
    "section.edit": "Edit Section",
    "section.delete": "Delete Section",
    "section.showContents": "Show Contents",

    //ContentConnector
    contentConnector: "Data Source",
    contentConnectors: "Data Sources",
    contentConnectorType: "Data Source",
    "contentConnector.delete": "Delete data source",
    "contentConnector.create": "Create a new data source",
    "contentConnector.deleteAll": "Delete all data sources",
    "contentConnectors.view": "View content types",

    //Content Builder Model
    contentBuilderModel: "Content Builder Model",
    "contentBuilderModel.create": "Create a new content builder model",
    "contentBuilderModel.delete": "Delete content builder model",
    "contentBuilderModel.deleteAll": "Delete all content builder models",
    "contentBuilderModel.edit": "Edit content builder model",

    //messages
    saveConfirm:
        "Please save the current changes before switching to the other tab.",
    saveBeforSwitch:
        "Please save the current changes before switching to the Editor.",
    itemsPerPageSwitch:
        "If you change the items per page, all selected items will not be selected anymore. ",

    //Css Editor
    page: "Page",
    pageNumber: "Page number",

    //Textblocks
    textBlocks: "Textblocks",
    "textBlocks.delete": "Delete textblock",
    "textBlocks.deleteAll": "Delete all textblocks",
    "textBlocks.edit": "Edit textblock",
    "textBlocks.create": "Create a new textblock",

    textBlock: "Textblock",
    "textBlock.delete": "Delete textblock",
    "textBlock.edit": "Edit Textblock",
    "textBlock.create": "Create Textblock",

    //Index Table
    indexTable: "Index Table",
    "indexTable.indexType": "Headline",
    "indexTable.property": "Property",
    "indexTable.headline": "Headline",
    "indexTable.indexTypePlaceHolder": "Select Index Type",
    "indexTable.propertyPlaceHolder": "Select Property",
    "indexTable.headlinePlaceHolder": "Insert Headline",
    "indexTable.create": "Create a new index",
    "indexTable.edit": "Edit the index",
    "indexTable.delete": "Delete index",
    "indexTable.deleteAll": "Delete all indices",

    // CSS
    "css.management": "Css-Management",
    cssSelectorName: "CSS selector name",
    css: "CSS",
    cssFile: "CSS File",
    "placeHolder.css": "Insert CSS here..",
    invalidCssSelectorName: "Invalid CSS selectorname",
    "css.delete": "Delete css file",
    "css.create": "Create a new css file",
    "css.edit": "Edit css file",
    "css.created": "Css file created successfully",
    "css.edited": "Css file edited successfully",

    //Output
    outputManagement: "Output Management",
    outputConfig: "Output Configuration",
    outputConfigType: "Output Configuration Type",
    "OutputManagement.OutputTypePlaceHolder": "Select Output Type",

    //Laout Generator
    layoutGenerator: "Layout Generator",
    layoutGeneratorConfig: "Layout Generator Configuration",

    //input
    left: "Left",
    right: "Right",
    top: "Top",
    bottom: "Bottom",
    imageUrl: "Image-Url",
    backgroundColor: "Backgroundcolor",
    font: "Font",
    fontFamily: "Fontfamily",
    fontSize: "Fontsize",
    lineHeight: "Lineheight",
    fontColor: "Fontcolor",
    suffix: "Suffix",
    width: "Width",
    height: "Height",
    columnCount: "Number of columns",
    paddingLeft: "Padding left",
    paddingRight: "Padding right",
    paddingTop: "Padding top",
    paddingBottom: "Padding bottom",
    margin: "Margin",
    withPageNumber: "With pagenumber",
    background: "Background",
    align: "Align",
    spaces: "Spaces",

    recipientList: "Recipient List",
    dashboard: "Dashboard",
    content: "Contents",
    contentTypes: "Content Types",
    users: "Users",
    recipient: "Recipient",
    contentBuilderModel: "Content Builder Model",
    "sideNav.contentSelector": "Content Selector",
    "confirm.message": "Are you sure you wish to delete this item?",
    "confirm.yes": "Yes",
    "confirm.no": "No",
    register: "Register",
    password: "Password",
    changePassword: "Change password",
    "password.old": "Old password",
    "password.new": "New password",
    "password.confirm": "Confirm password",
    viewLayoutJob: "View layout job",
    childLayoutJob: "Child layout job",
    back: "Back",
    error: "Error",
    parentLayoutJobId: "Parent layout job",
    detailView: "Detail view",
    "upload.file": "Upload file",
    file: "File",
    welcome: "Welcome",
    home: "Home",
    account: "Account",
    title: "Title",
    firstName: "First name",
    surname: "Surname",
    foreignId: "Foreign system Id",
    street: "Street",
    zipCode: "Zip Code",
    city: "City",
    country: "country",
    mail: "Mail",
    salutation: "Salutation",
    voucherCode: "Voucher code",
    voucherExpirationDate: "Voucher code expiration date",
    "header.account": "Account",
    "header.logout": "Logout",
    "login.welcomeText": "english welcome text... ",
    "login.goToLogin": "go to login",
    "content.list": "Content List",
    slNum: "#",
    icon: "Icon",
    label: "Label",
    name: "Name",
    modifiedAt: "Modified At",
    modifiedBy: "Modified By",
    createdAt: "Created At",
    createdBy: "Created By",
    search: "Search",
    surname: "Surname",
    firstName: "First Name",
    eMail: "E-Mail",
    alsJobId: "ALS Job ID",
    alsJobStatus: "ALS Job Status",
    foreignSystemId: "Foreign System Id",
    productId: "Product ID",
    subtitle: "Subtitle",
    description: "Description",
    currency: "Currency",
    priceGross: "Price gross",
    link: "Link URL",
    externalImage: "external Image URL",
    specialOfferGross: "special offer gross",
    "content.create": "Create Content",
    "content.edit": "Edit Content",
    "recipient.create": "New recipient",
    "recipient.import": "Select recipients",
    "recipient.edit": "Edit recipient",
    "content.create": "Create a new content",
    "content.import": "Import contents from an Excel file",
    "content.delete": "Delete content",
    "contentType.create": "Create a new Content Type",
    "content.edit": "Edit content",
    "job.delete": "Delete Job",
    "recipienent.delete": "Delete recipient",
    "layoutJob.create": "Create Layout Job",
    "layoutJob.view": "View Layout Jobs",
    "chapter.view": "View chapters",
    "job.deleteAll": "Delete all jobs",
    "user.deleteAll": "Delete all users",
    "recipient.deleteAll": "Delete all recipients",
    "contentGroups.deleteAll": "Delete all content groups",
    "chapters.deleteAll": "Delete all chapters",
    "contentType.list": "Content Type List",
    "contentType.new": "New Content Type",
    "contentType.edit": "Edit Content Type",
    contentType: "Content Type",
    "content.create": "Create a new content type",
    "contentType.delete": "Delete content type",
    imageUrl: "Image URL",
    cssSelectorName: "CSS selector name",
    contentTypeFieldName: "Contenttype field name",
    inputType: "Inputtypes",
    "checkbox.isRequired": "is required",
    "checkbox.useForAls": "use for ALS",
    "input.select": "Select",
    "label.user.list": "User List",
    "label.user.create": "Create User",
    "label.user.edit": "Edit User",
    "label.user.fullName": "Full Name",
    "label.user.userName": "User Name",
    "tooltip.user.delete": "Delete User",
    "tooltip.user.edit": "Edit User",
    "label.resultsPerPage": "Results Per Page ",
    "button.save": "Save",
    "button.goToList": "Go to List",
    "button.sendouts": "Sendouts",
    "placeHolder.content.search": "Enter a search string",
    "placeHolder.input.foreignSystemId": "Insert foreign system ID",
    "placeHolder.input.name": "Insert name",
    "placeHolder.input.productId": "Insert Product ID",
    "placeHolder.input.subtitle": "Insert Subtitle",
    "placeHolder.input.description": "Insert Description",
    "placeHolder.input.currency": "Insert Currency",
    "placeHolder.input.priceGross": "Insert price gross",
    "placeHolder.input.link": "Insert link URL",
    "placeHolder.input.externalImage": "Insert external image URL",
    "placeHolder.input.specialOfferGross": "Insert special offer gross",
    "error.input.invalidName": "Invalid name",
    "error.input.invalidImageUrl": "Invalid image URL",
    "error.input.invalidCssSelectorName": "Invalid CSS selectorname",
    "error.input.default": "Invalid input",
    "error.form.fillForm": "Please fill the required fields",
    "info.noData": "No data available",

    // "chapter.delete": "Delete Chapter",
    "session.expiry": "Your session will expire in ",
    deleteAll: "Are you sure you wish to delete all items?",
    import: "Import items from a file",
    deleteAll: "Delete All",
    "contentGroup.create": "Edit Content Group",
    "contentGroup.delete": "Delete Content Group",
    confirm: "Confirm",
    reset: "Reset",
    contentGroup: "Content Group",
    css: "CSS",
    minPageCount: "Minimum Page Count",
    maxPageCount: "Maximum Page Count",
    cssFile: "CSS File",
    css: "Enter CSS here..",
    newSession: "New Session",

    itemsPerPage: "Items per page",
    saveContentGroup: "Save Content Group",
    cancel: "Cancel",
    createNew: "Create New",
    "delete.all": "Are you sure you wish to delete all items?",
    "chapter.create": "Create chapter",
    "chapter.mapContents": "Map contents to chapters",
    "wizard.layout.generate": "Generate layouts",
    "wizard.steps.completed": "All steps were executed successfully",
    "user.create": "Create user",
    "delete.selected": "Are you sure you wish to delete all selected items?",
    "delete.single": "Are you sure you wish to delete this item?",
    drag: "Drag and Drop or click to browse",
    "cbmcontentBuilderModel.crate": "Create Content Builder Model",
    "selected.pre": "You have selected",
    "selected.post": "entries!",
    // "chapter.delete": "Chapter deleted Successfully",
    "content.edit": "Content edited Successfully",
    "content.delete": "Content deleted Successfully",
    "content.create": "Content created Successfully",
    "job.edit": "Job edited Successfully",
    "job.delete": "Job deleted Successfully",
    "job.create": "Job created Successfully",
    "recipient.edit": "Recipient edited Successfully",
    "recipient.delete": "Recipient deleted Successfully",
    "recipient.create": "Recipient created Successfully",
    "user.edit": "User edited Successfully",
    "user.delete": "User deleted Successfully",
    "user.create": "User created Successfully",
    "password.edit": "Password changed Successfully",
    "file.reject": "Rejected File-type!",
    "file.unknown":
        "File type was not detected! It could be that this file is not compatible with the system.",
    "import.success": "Import success",
    "session.expire": "Your session will expire in ",
    ".unknown": "Something went wrong :(",
    ".input.invalid": "Invalid input",
    "sessionTimeOut.pre": "Your session will expire in ",
    "sessionTimeOut.post": " Please start a new session to continue.",
    "sessionTimeOut.minutes": " minutes & ",
    "sessionTimeOut.seconds": " seconds.",

    //Styling Templates
    "stylingTemplate.headline": "Template",
    "stylingTemplate.create": "New Template",
    "stylingTemplate.edit": "Edit Template",
    "stylingTemplate.select": "Select Template",
    "stylingTemplate.shared": "AutLay Style Templates",
    "stylingTemplate.local": "Custom Style Templates",
    "stylingTemplate.fitLook": "Customize appearance",
    "fontWeights.fw100": "ultra light",
    "fontWeights.fw200": "extra light",
    "fontWeights.fw300": "light",
    "fontWeights.fw400": "regular",
    "fontWeights.fw500": "medium",
    "fontWeights.fw600": "semi bold",
    "fontWeights.fw700": "bold",
    "fontWeights.fw800": "extra bold",
    "fontWeights.fw900": "ultra bold",
    "labels.h1": "Headline",
    "labels.h2": "Subheading",
    "labels.letter": "Cover letter",
    "labels.date": "Date",
    "labels.default": "Standard format",
    "labels.primary": "Primary formatting",
    "labels.secondary": "Secondary formatting",
    "labels.product": "Product",
    "labels.product-price": "Product price",
    "labels.logo": "Your logo",
    "labels.header": "Header",
    "labels.footer": "Footer",
    "font.family": "Font",
    "font.size": "Font size (in pt)",
    "font.weight": "Font weight",
    "font.color": "Font color",
    "previewImages.example": "Exemplary representation",
    "previewImages.frontpage": "Front page",
    "previewImages.backpage": "Back page",

    "mustHave.front": "Contents: Front Page",
    "mustHave.last": "Contents: Back page",
    "mustHave.fixPosition": "Fixed position",

    "AssetManager.upload": "Select a file or add a new one",
    "AssetManager.latestUploadedFiles": "Last uploaded files",
    "AssetManager.button": "Add logo",
};
